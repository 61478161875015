import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ReactLoading from "react-loading";

import {
  Container,
  Row,
  Col,
  FormInput,
  Alert,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../ApiUrl");

export default class ViewFollowUp extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.InputFields = this.InputFields.bind(this);
    this.sendData = this.sendData.bind(this);
    this.alert = this.alert.bind(this);
    this.conditions = this.conditions.bind(this);
    this.conditions2 = this.conditions2.bind(this);
    this.getFormFields = this.getFormFields.bind(this);
    this.state = {
      status: false,
      isLoading: true
    };
  }

  handleChange(e) {
    console.log(this.state);
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  async sendData(e) {
    e.preventDefault();
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let stateData = Object.assign({}, this.state);

    delete stateData.formId;
    delete stateData.name;
    delete stateData.ipNo;
    delete stateData.status;
    delete stateData.token;
    delete stateData.followdata;
    delete stateData.isLoading;
    delete stateData.id;

    let followUpData = {
      followdata: stateData,
      name: this.state.name,
      ipNo: this.state.ipNo,
      formId: this.state.formId,
      id: this.state.id
    };

    console.log(followUpData);
    console.log("Sening");
    let response = await axios
      .patch(`${URL.BASE_URL}/forms/updatefollow`, followUpData, config)
      .catch(function(error) {
        console.log(error);
        return;
      });

    if (response) {
      if (response.data.statusCode === 200) {
        this.setState({
          status: true,
          isCompleted: true
        });
      } else {
        this.setState({
          status: false
        });
      }
    }

    setTimeout(() => {
      this.setState({
        status: false
      });
    }, 2000);

    console.log(response);
  }

  async getFormFields() {
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

    let id = this.props.match.params.id;
    let response2 = await axios({
      method: "get",
      url: `${URL.BASE_URL}/forms/getfollowup/${id}`,

      headers: { Authorization: `Bearer ${token}` }
    });
    if (response2.data.statusCode === 200) {
      this.setState(response2.data.data.fields);
      this.setState({
        //fieldValue: response2.data.data.fields,
        name: response2.data.data.name,
        ipNo: response2.data.data.ipNo,
        token: token,
        isLoading: false,
        id: response2.data.data._id
      });
    }

    console.log(this.state.fieldValue);
    //console.log(response.data.data);
  }

  alert() {
    if (this.state.status) {
      return (
        <Alert className="mb-0">
          <i className="fa fa-info mx-2"></i> Successfully added fields
        </Alert>
      );
    } else {
      return <div></div>;
    }
  }

  conditions() {
    if (this.state.recurrent === "Yes") {
      return (
        <Row>
          <FormGroup>
            <Col md="5" sm="12">
              <FormGroup>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText
                      style={{
                        color: "#99a",
                        "font-size": "14px",
                        "font-weight": "500"
                      }}
                    >
                      No. of times hospitalized
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    type="number"
                    value={this.state.notimeshosp}
                    onChange={this.handleChange}
                    name="notimeshosp"
                  ></FormInput>
                </InputGroup>
              </FormGroup>
            </Col>
            <hr />
          </FormGroup>
          <hr />
        </Row>
      );
    } else {
      return <div></div>;
    }
  }
  conditions2() {
    if (this.state.mortality === "Yes") {
      return (
        <>
          <Row>
            <Col>
              <label htmlFor="date"> Date of death : </label>
              &nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;
              <input
                type="date"
                id="date"
                name="date_of_death"
                value={this.state.date_of_death}
                onChange={this.handleChange}
                style={{
                  padding: "5px 5px",
                  margin: "8px 0",
                  "box-sizing": "border-box",
                  color: "#ccc",
                  border: "1px solid #ccc",
                  "border-radius": "4px"
                }}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md="3">Cause of Death :</Col>
            <input
              type="radio"
              id="radio1"
              name="cause_death"
              checked={this.state.cause_death === "Cardiac"}
              value="Cardiac"
              onChange={this.handleChange}
              style={{
                marginTop: "2px",
                marginRight: "10px",
                fontSize: "16px",
                border: "0px",
                width: "20px",
                height: "20px"
              }}
            ></input>
            Cardiac
            <input
              type="radio"
              id="radio2"
              name="cause_death"
              value="Non Cardiac"
              onChange={this.handleChange}
              checked={this.state.cause_death === "Non Cardiac"}
              style={{
                marginTop: "2px",
                marginLeft: "20px",
                marginRight: "10px",
                fontSize: "16px",
                border: "0px",
                width: "20px",
                height: "20px"
              }}
            ></input>
            Non cardiac
          </Row>
          <hr />
        </>
      );
    } else {
      return <div></div>;
    }
  }

  InputFields() {
    let alive = false;
    let dead = false;
    let same = false;
    let better = false;
    let worse = false;
    if (this.state.alivedead === "alive") {
      alive = true;
    } else {
      dead = true;
    }

    if (this.state.symptomSts === "Same") {
      same = true;
    } else if (this.state.symptomSts === "Better") {
      better = true;
    } else {
      worse = true;
    }
    return (
      <div>
        {" "}
        <h2>Followup details</h2>
        <hr />
        <Row>
          <Col md="6">
            <div
              style={{
                fontSize: "16px",
                fontWeight: "1.2rem"
              }}
            >
              Name : {this.state.name} <br />
              IP No : {this.state.ipNo}
              <br />
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <FormGroup>
            <Col md="6" sm="12">
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon type="prepend">
                    <InputGroupText
                      style={{
                        color: "#99a",
                        "font-size": "14px",
                        "font-weight": "500"
                      }}
                    >
                      Duration - Month
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    type="text"
                    name="duration"
                    value={this.state.duration}
                    onChange={this.handleChange}
                  ></FormInput>
                </InputGroup>
              </FormGroup>
            </Col>
            <hr />
          </FormGroup>
        </Row>
        <Row>
          <Col>
            <label htmlFor="date"> Date of Follow Up : </label>
            &nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;
            <input
              type="date"
              id="date"
              name="date_of_follow"
              onChange={this.handleChange}
              value={this.state.date_of_follow}
              style={{
                padding: "5px 5px",
                margin: "8px 0",
                "box-sizing": "border-box",
                color: "#ccc",
                border: "1px solid #ccc",
                "border-radius": "4px"
              }}
            />
          </Col>
        </Row>
        <hr />
        <div>
          <FormGroup>
            <Row>
              <Col md="3">Alive/Dead :</Col>
              <input
                type="radio"
                id="radio1"
                name="alivedead"
                value="alive"
                checked={alive}
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Alive
              <input
                type="radio"
                id="radio2"
                name="alivedead"
                value="dead"
                checked={dead}
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Dead
            </Row>
            <hr />
            <Row>
              <Col md="3">Symptom status :</Col>
              <input
                type="radio"
                id="radio2"
                name="symptomSts"
                value="Same"
                onChange={this.handleChange}
                checked={same}
                style={{
                  marginTop: "2px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              same
              <input
                type="radio"
                id="radio4"
                name="symptomSts"
                value="Better"
                onChange={this.handleChange}
                checked={better}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Better
              <input
                type="radio"
                id="radio5"
                name="symptomSts"
                value="Worse"
                onChange={this.handleChange}
                checked={worse}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Worse
            </Row>
            <hr />{" "}
          </FormGroup>

          <Row>
            <Col md="3">Medication adherence :</Col>
            <FormGroup>
              <input
                type="radio"
                id="radio6"
                name="medication"
                value="Yes"
                checked={this.state.medication === "Yes"}
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Yes
              <input
                type="radio"
                id="radio7"
                name="medication"
                value="No"
                onChange={this.handleChange}
                checked={this.state.medication === "No"}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              No
            </FormGroup>
          </Row>
          <hr />

          <Row>
            <Col md="3">Recurrent hospitalisation </Col>
            <FormGroup>
              <input
                type="radio"
                id="radio8"
                name="recurrent"
                value="Yes"
                onChange={this.handleChange}
                checked={this.state.recurrent === "Yes"}
                style={{
                  marginTop: "2px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Yes
              <input
                type="radio"
                id="radio9"
                name="recurrent"
                value="No"
                onChange={this.handleChange}
                checked={this.state.recurrent === "No"}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              No
            </FormGroup>
          </Row>
          <hr />

          <this.conditions></this.conditions>

          <Row>
            <Col md="3">Mortality :</Col>
            <FormGroup>
              <input
                type="radio"
                id="radio10"
                name="mortality"
                checked={this.state.mortality === "Yes"}
                value="Yes"
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Yes
              <input
                type="radio"
                id="radio11"
                name="mortality"
                value="No"
                checked={this.state.mortality === "No"}
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              No
            </FormGroup>
          </Row>
          <hr />

          <this.conditions2></this.conditions2>

          <Row>
            <FormGroup>
              <Col md="5" sm="12">
                <FormGroup>
                  <InputGroup className="mb-3">
                    <InputGroupAddon type="prepend">
                      <InputGroupText
                        style={{
                          color: "#99a",
                          "font-size": "14px",
                          "font-weight": "500"
                        }}
                      >
                        Additional information, if any
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput
                      type="text"
                      name="addinfo"
                      onChange={this.handleChange}
                      value={this.state.addinfo}
                    ></FormInput>
                  </InputGroup>
                </FormGroup>
              </Col>
              <hr />
            </FormGroup>
          </Row>
        </div>
        <Button
          size="sm"
          theme="success"
          className="mb-2 mr-1"
          onClick={this.sendData}
        >
          Submit
        </Button>
      </div>
    );
  }

  AddNewField = () => {
    return (
      <>
        <this.alert />
        <Container fluid className="main-content-container px-4 pb-4">
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title="Create Form"
              subtitle="Follow Upn Data"
              className="text-sm-left"
            />
          </Row>

          <Row>
            <Col md="6">
              <this.InputFields />
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidMount() {
    this.getFormFields();
  }

  render() {
    if (this.state.isCompleted) {
      return <Redirect to="../dashbord" />;
    } else if (this.state.isLoading) {
      return (
        <div
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            marginLeft: "40%"
          }}
        >
          <Row className="justify-content-md-center">
            <Col lg="2">
              <ReactLoading type={"spinningBubbles"} color={"#5af"} />
              {/* <h2>Loading...</h2> */}
            </Col>
          </Row>
        </div>
      );
    } else return this.AddNewField();
  }
}
