import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  FormSelect,
  FormInput,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  Alert
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Cookie from "js-cookie";
import ReactLoading from "react-loading";

const axios = require("axios").default;
const URL = require("../ApiUrl");

export default class AddFormData extends Component {
  constructor(props) {
    super(props);

    this.getFormFields = this.getFormFields.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.RenderFields = this.RenderFields.bind(this);
    this.firstSend = this.firstSend.bind(this);
    this.nextData = this.nextData.bind(this);
    this.prevData = this.prevData.bind(this);
    this.BottomButton = this.BottomButton.bind(this);
    this.TopTab = this.TopTab.bind(this);
    this.CustomRadio = this.CustomRadio.bind(this);
    this.CustomCheckBox = this.CustomCheckBox.bind(this);
    this.redirectState = this.redirectState.bind(this);
    this.LabParameterTable = this.LabParameterTable.bind(this);
    this.Validation = this.Validation.bind(this);
    this.state = {
      formFields: [],
      fieldValue: {},
      checkBoxValue: [],
      sliceArray: [],
      start: 0,
      end: 1,
      id: null,
      selectedFile: false,
      isLoading: false
    };
  }

  async getFormFields() {
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let response = await axios
      .get(`${URL.BASE_URL}/fields/get`, config)
      .catch(function(error) {
        console.log(error);
        this.setState({
          error: error
        });
      });
    if (response) {
      this.setState({
        formFields: response.data.data
      });
      //console.log(response.data.data);

      let fields = [];
      let sliceNo = [];

      fields = response.data.data;

      fields.forEach((item, key) => {
        // console.log(key, item.fieldType);
        if (item.fieldType === "title") {
          sliceNo.push(key);
        }
      });
      sliceNo.push(fields.length);

      this.setState({
        sliceArray: sliceNo
      });

      //------

      let id = this.props.match.params.id;
      let response2 = await axios({
        method: "get",
        url: `${URL.BASE_URL}/forms/singledata/${id}`,

        headers: { Authorization: `Bearer ${token}` }
      });

      this.setState({
        fieldValue: response2.data.data.fields,
        token: token
      });
      console.log(this.state.fieldValue);
      //console.log(response.data.data);
    }
  }

  handleChange(e) {
    var merged;
    let newItem;
    let oldItem = this.state.fieldValue;

    if (
      e.target.name == "date_of_admission_741" ||
      e.target.name == "date_of_discharge_177"
    ) {
      const date1 = new Date(this.state.fieldValue.date_of_admission_741);
      const date2 = new Date(e.target.value);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      console.log(date1, date2);
      console.log(diffDays + " days");

      newItem = { [e.target.name]: e.target.value, total_days_308: diffDays };
    } else {
      newItem = { [e.target.name]: e.target.value };
    }

    merged = { ...oldItem, ...newItem };
    this.setState({
      fieldValue: merged
    });
  }

  handleCheck(e) {
    let item = e.target.name;
    let value = e.target.value;
    let newItem = [];

    if (e.target.checked) {
      if (this.state[item]) {
        newItem = this.state[item];
      }
      newItem.push(value);
    } else {
      if (this.state[item]) {
        newItem = this.state[item];
      }
      newItem.pop(value);
    }

    this.setState({
      [item]: newItem
    });
  }

  nextData() {
    this.setState({
      isLoading: true
    });

    setTimeout(
      function() {
        this.setState({ isLoading: false });
      }.bind(this),
      250
    );

    this.setState({
      validation: false
    });
    let oldStart = this.state.start;
    let oldEnd = this.state.end;
    this.setState({
      start: oldStart + 1,
      end: oldEnd + 1
    });
    window.scrollTo(0, 0);
    this.firstSend();
  }
  prevData() {
    let oldStart = this.state.start;
    let oldEnd = this.state.end;
    this.setState({
      start: oldStart - 1,
      end: oldEnd - 1
    });
    window.scrollTo(0, 0);
  }

  async firstSend(e) {
    //e.preventDefault();
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let stateData = Object.assign({}, this.state);
    delete stateData.formFields;
    delete stateData.fieldValue;
    delete stateData.checkBoxValue;
    delete stateData.start;
    delete stateData.end;
    delete stateData.sliceArray;
    delete stateData.id;
    delete stateData.selectedFile;
    delete stateData.token;
    let dataToSend = { ...this.state.fieldValue, ...stateData };

    console.log(dataToSend);
    console.log("Sening");
    let response;
    if (this.state.start + 1 === this.state.sliceArray.length - 1) {
      dataToSend.isCompleted = true;
    }
    dataToSend.id = this.props.match.params.id;
    response = await axios
      .patch(`${URL.BASE_URL}/forms/updatedata`, dataToSend, config)
      .catch(function(error) {
        console.log(error);
      });
    console.log(response);

    console.log(this.state);
  }

  redirectState() {
    this.firstSend();
    this.setState({
      isCompleted: true
    });
  }

  pagination(pageNo) {
    this.setState({
      isLoading: true
    });

    setTimeout(
      function() {
        this.setState({ isLoading: false });
      }.bind(this),
      250
    );

    this.setState({
      start: pageNo - 1,
      end: pageNo
    });
    window.scrollTo(0, 0);
    this.firstSend();
  }

  TopTab() {
    return (
      <div>
        <nav>
          <ul className="pagination">
            {this.state.sliceArray.map((item, index) => {
              let titleIndex = this.state.sliceArray;
              if (index === this.state.start + 1) {
                return (
                  <li className="page-item ">
                    <Button
                      size="sm"
                      theme="info"
                      className="mb-2 mr-1"
                      onClick={() => this.pagination(index)}
                    >
                      {index} .
                      {this.state.formFields[titleIndex[index - 1]].fieldName}
                    </Button>
                  </li>
                );
              } else if (index === 0) {
                return <div></div>;
              } else {
                return (
                  <li className="page-item">
                    <Button
                      size="sm"
                      outline
                      theme="info"
                      className="mb-2 mr-1"
                      onClick={() => this.pagination(index)}
                    >
                      {index} .
                      {this.state.formFields[titleIndex[index - 1]].fieldName}
                    </Button>
                  </li>
                );
              }
            })}
          </ul>
        </nav>
      </div>
    );
  }

  BottomButton() {
    let start = this.state.start;
    let end = this.state.end;
    // console.log(this.state.sliceArray, start, end);
    let arrayLength = this.state.sliceArray.length;
    if (start === 0) {
      return (
        <div>
          <nav>
            <ul className="pagination">
              <li className="page-item">
                <Button
                  size="sm"
                  theme="warning"
                  className="mb-2 mr-1"
                  onClick={this.prevData}
                >
                  Prev
                </Button>
              </li>
              {this.state.sliceArray.map((item, index) => {
                if (index === this.state.start + 1) {
                  return (
                    <li className="page-item ">
                      <Button
                        size="sm"
                        theme="info"
                        className="mb-2 mr-1"
                        onClick={() => this.pagination(index)}
                      >
                        {index}
                      </Button>
                    </li>
                  );
                } else if (index === 0) {
                  return <div></div>;
                } else {
                  return (
                    <li className="page-item">
                      <Button
                        size="sm"
                        outline
                        theme="info"
                        className="mb-2 mr-1"
                        onClick={() => this.pagination(index)}
                      >
                        {index}
                      </Button>
                    </li>
                  );
                }
              })}

              <li className="page-item">
                <Button
                  size="sm"
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={this.nextData}
                >
                  Next
                </Button>
              </li>
            </ul>
          </nav>
        </div>
      );
    } else if (start === arrayLength - 2 && end === arrayLength - 1) {
      return (
        <div>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <Button
                  size="sm"
                  theme="warning"
                  className="mb-2 mr-1"
                  onClick={this.prevData}
                >
                  Prev
                </Button>
              </li>
              {this.state.sliceArray.map((item, index) => {
                if (index === this.state.start + 1) {
                  return (
                    <li className="page-item ">
                      <Button
                        size="sm"
                        theme="info"
                        className="mb-2 mr-1"
                        onClick={() => this.pagination(index)}
                      >
                        {index}
                      </Button>
                    </li>
                  );
                } else if (index === 0) {
                  return <div></div>;
                } else {
                  return (
                    <li className="page-item">
                      <Button
                        size="sm"
                        outline
                        theme="info"
                        className="mb-2 mr-1"
                        onClick={() => this.pagination(index)}
                      >
                        {index}
                      </Button>
                    </li>
                  );
                }
              })}

              <li className="page-item">
                <Button
                  size="sm"
                  theme="success"
                  className="mb-2 mr-1"
                  onClick={this.redirectState}
                >
                  Submit
                </Button>
              </li>
            </ul>
          </nav>
        </div>
      );
    } else if (start > 0 && end < arrayLength) {
      return (
        <div>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <Button
                  size="sm"
                  theme="warning"
                  className="mb-2 mr-1"
                  onClick={this.prevData}
                >
                  Prev
                </Button>
              </li>
              {this.state.sliceArray.map((item, index) => {
                if (index === this.state.start + 1) {
                  return (
                    <li className="page-item ">
                      <Button
                        size="sm"
                        theme="info"
                        className="mb-2 mr-1"
                        onClick={() => this.pagination(index)}
                      >
                        {index}
                      </Button>
                    </li>
                  );
                } else if (index === 0) {
                  return <div></div>;
                } else {
                  return (
                    <li className="page-item">
                      <Button
                        size="sm"
                        outline
                        theme="info"
                        className="mb-2 mr-1"
                        onClick={() => this.pagination(index)}
                      >
                        {index}
                      </Button>
                    </li>
                  );
                }
              })}

              <li className="page-item">
                <Button
                  size="sm"
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={this.nextData}
                >
                  Next
                </Button>
              </li>
            </ul>
          </nav>
        </div>
      );
    } else {
      return <div>" "</div>;
    }
  }

  CustomRadio({ name, item }) {
    if (item === this.state.fieldValue[name]) {
      return (
        <>
          <input
            type="radio"
            id="radio1"
            name={name || "name"}
            value={item || "item"}
            onChange={this.handleChange}
            style={{
              marginTop: "2px",
              marginRight: "10px",
              fontSize: "16px",
              border: "0px",
              width: "20px",
              height: "20px"
            }}
            defaultChecked
          ></input>
          {item}
        </>
      );
    } else {
      return (
        <>
          <input
            type="radio"
            id="radio1"
            name={name || "name"}
            value={item || "item"}
            onChange={this.handleChange}
            style={{
              marginTop: "2px",
              marginRight: "10px",

              fontSize: "16px",
              border: "0px",
              width: "20px",
              height: "20px"
            }}
          ></input>
          {item}
        </>
      );
    }
  }

  CustomCheckBox({ name, item }) {
    let arr = [];
    if (this.state[name]) {
      arr = this.state[name];
    } else if (this.state.fieldValue[name]) {
      arr = this.state.fieldValue[name];
    }
    // console.log(name, item);
    let isChecked = arr.includes(item);
    return (
      <>
        <Col md="12">
          <input
            type="checkbox"
            id="checkbox1"
            style={{
              fontSize: "16px",
              border: "0px",
              width: "20px",
              height: "20px"
            }}
            defaultChecked={isChecked}
            name={name}
            value={item}
            onChange={this.handleCheck}
          />
          &nbsp; &nbsp; &nbsp;
          {item}
        </Col>
      </>
    );
  }

  LabParameterTable() {
    return (
      <div>
        <table className="table mb-0">
          <thead className="bg-light">
            <tr>
              <th scope="col" className="border-0">
                Parameter
              </th>
              <th scope="col" className="border-0">
                Test 1
              </th>
              <th scope="col" className="border-0">
                Test 2
              </th>
              <th scope="col" className="border-0">
                Test 3
              </th>
              <th scope="col" className="border-0">
                Test 4
              </th>
              <th scope="col" className="border-0">
                Test 5
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td>
                <input
                  type="text"
                  name={"first_sample_111"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["first_sample_111"]}
                  placeholder="First Sample"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"hours_112"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["hours_112"]}
                  placeholder="Hours"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"hours_113"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["hours_113"]}
                  placeholder="Hours"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"hours_114"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["hours_114"]}
                  placeholder="Hours"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"hours_115"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["hours_115"]}
                  placeholder="Hours"
                />
              </td>
            </tr>
            <tr>
              <td>Lympocyte Count</td>
              <td>
                <input
                  type="text"
                  name={"value_112"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_112"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_113"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_113"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_114"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_114"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_115"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_115"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_116"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_116"]}
                  placeholder="Enter"
                />
              </td>
            </tr>
            <tr>
              <td>CRP </td>
              <td>
                <input
                  type="text"
                  name={"value_122"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_122"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_123"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_123"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_124"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_124"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_125"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_125"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_126"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_126"]}
                  placeholder="Enter"
                />
              </td>
            </tr>
            <tr>
              <td>D Dimer </td>
              <td>
                <input
                  type="text"
                  name={"value_131"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_131"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_132"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_132"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_133"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_133"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_134"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_134"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_135"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_135"]}
                  placeholder="Enter"
                />
              </td>
            </tr>
            <tr>
              <td>Troponin </td>
              <td>
                <input
                  type="text"
                  name={"value_141"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_141"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_142"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_142"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_143"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_143"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_144"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_144"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_145"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_145"]}
                  placeholder="Enter"
                />
              </td>
            </tr>
            <tr>
              <td>BNP/ NT proBNP</td>
              <td>
                <input
                  type="text"
                  name={"value_151"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_151"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_152"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_152"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_153"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_153"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_154"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_154"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_155"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_155"]}
                  placeholder="Enter"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  Validation({ min, max, value, name }) {
    if (value < min || value > max) {
      return (
        <>
          <Alert theme="warning">
            {name} - Must greater than {min} and less than {max}
          </Alert>
        </>
      );
    } else {
      return <div></div>;
    }
  }

  RenderFields() {
    //For Second page other if condition
    let arr = [];

    var found;
    //   console.log(`%c --------------- `, "background: #dda; color: #ff0000");// color console
    //endd --------------------------------------
    let fields = [];

    if (this.state.formFields) {
      fields = this.state.formFields;
    }

    let start = 0;
    let end = this.state.sliceArray[this.state.end];
    if (this.state.start > 0) {
      start = this.state.sliceArray[this.state.start];
    }

    return (
      <div>
        {fields.slice(start, end).map((field, key) => {
          var parent_name;
          var parent_value = field.parentValue;
          var parent_curent_value;
          var parent_curent_value_array = [];
          var parent_name_array = [];
          var parent_value_array = [];

          let colSize = 6;

          if (field.parentSlug.length === 1) {
            parent_name = field.parentSlug[0];
            parent_curent_value = this.state.fieldValue[parent_name];
            //  console.log(parent_name);
            //  console.log(parent_value);
            //  console.log(parent_curent_value);
          }
          if (field.parentSlug.length > 1) {
            parent_name_array = field.parentSlug;
            parent_value_array = field.parentValue;
            parent_name_array.forEach(parent_item => {
              parent_curent_value_array.push(
                this.state.fieldValue[parent_item]
              );
            });
            {
              /*
            console.log("||||||||||||||||||||||||||||");
            console.log("length", field.parentSlug.length);
            console.log(parent_curent_value_array);
            console.log("array", parent_value_array);
            console.log(
              "Valuse",
              JSON.stringify(parent_value_array) ==
                JSON.stringify(parent_curent_value_array)
            );
            */
            }
          }

          if (parent_name && parent_curent_value !== parent_value) {
            return (
              <div>
                {/*  "parent_name" : {parent_name} <br />
                "parent_value" : {parent_value} <br />
                "parent_current_value" : {parent_curent_value}
                */}
              </div>
            );
          } else if (
            field.parentSlug.length > 1 &&
            JSON.stringify(parent_value_array) !==
              JSON.stringify(parent_curent_value_array)
          ) {
            return (
              <div>
                {/* 
                "field Name" : {field.fieldName} <br />
                "Pos" : {field.pos}
                "Parent Count" : {field.parentSlug.length}
                 */}
              </div>
            );
          } else if (field.fieldType === "text") {
            return (
              <>
                <Col lg="6" md={colSize} sm="12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                          <strong style={{ color: "red" }}> *</strong>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="text"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                        value={this.state.fieldValue[field.fieldSlug]}
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <hr />
              </>
            );
          } else if (field.fieldType === "number") {
            return (
              <>
                <Col md={colSize} sm="12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                          <strong style={{ color: "red" }}> *</strong>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="number"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                        value={this.state.fieldValue[field.fieldSlug]}
                        min={field.minValue}
                        max={field.maxValue}
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                  <this.Validation
                    min={field.minValue}
                    max={field.maxValue}
                    value={this.state.fieldValue[field.fieldSlug]}
                    name={field.fieldName}
                  />{" "}
                </Col>

                <hr />
              </>
            );
          } else if (field.fieldType === "radio") {
            return (
              <>
                <Row>
                  <Col md={colSize - 3}>
                    {field.fieldName}{" "}
                    <strong style={{ color: "red" }}> *</strong> :{" "}
                  </Col>
                  {field.radioEnum.map(item => {
                    return (
                      <>
                        <this.CustomRadio name={field.fieldSlug} item={item} />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    );
                  })}
                </Row>
                <hr />
              </>
            );
          } else if (field.fieldType === "check_box") {
            return (
              <>
                <Row>
                  <Col
                    md={12}
                    style={{
                      fontSize: "18px",
                      marginBottom: "20px"
                    }}
                  >
                    {" "}
                    {field.fieldName}{" "}
                    <strong style={{ color: "red" }}> *</strong>
                  </Col>

                  {field.checkBoxEnum.map((item, key) => {
                    return (
                      <>
                        <Col md="4">
                          <this.CustomCheckBox
                            name={field.fieldSlug}
                            item={item}
                          />
                        </Col>
                      </>
                    );
                  })}
                </Row>

                <hr />
              </>
            );
          } else if (field.fieldType === "dropdown") {
            return (
              <>
                <Col md={colSize}>
                  <label htmlFor="feInputState">
                    {" "}
                    {field.fieldName}{" "}
                    <strong style={{ color: "red" }}> *</strong>{" "}
                  </label>
                  :{" "}
                  <FormSelect
                    id="feInputState"
                    name={field.fieldSlug}
                    onChange={this.handleChange}
                  >
                    <option>{this.state.fieldValue[field.fieldSlug]}</option>
                    {field.checkBoxEnum.map(item => {
                      return (
                        <>
                          <option>{item}</option>
                        </>
                      );
                    })}
                  </FormSelect>
                </Col>
                <hr />
              </>
            );
          } else if (field.fieldType === "date") {
            return (
              <>
                <Col>
                  <label htmlFor="date">
                    {" "}
                    {field.fieldName}{" "}
                    <strong style={{ color: "red" }}> *</strong>{" "}
                  </label>
                  &nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;
                  <input
                    type="date"
                    id="date"
                    name={field.fieldSlug}
                    onChange={this.handleChange}
                    style={{
                      padding: "5px 5px",
                      margin: "8px 0",
                      "box-sizing": "border-box",
                      color: "#ccc",
                      border: "1px solid #ccc",
                      "border-radius": "4px"
                    }}
                    value={this.state.fieldValue[field.fieldSlug]}
                    min={this.state.fieldValue.date_of_admission_741}
                    max="2020-12-31"
                  />
                </Col>
                <hr />
              </>
            );
          } else if (field.fieldType === "title") {
            return (
              <>
                <Col md="12">
                  <h2> {field.fieldName} </h2>
                  <hr />
                </Col>
              </>
            );
          } else if (field.fieldType === "sub_title") {
            return (
              <>
                <Col md={colSize}>
                  <h3>
                    {" "}
                    <strong> {field.fieldName} </strong>{" "}
                  </h3>
                  <hr />
                </Col>
              </>
            );
          } else if (field.fieldType === "note") {
            return (
              <>
                <Col md="12">
                  {" "}
                  <strong> {field.fieldName} </strong> <hr />
                </Col>
              </>
            );
          } else if (
            field.fieldType === "image" ||
            field.fieldType === "file"
          ) {
            return (
              <>
                {field.fieldName} &nbsp;&nbsp; : &nbsp;&nbsp;
                <hr />
              </>
            );
          } else if (field.fieldType === "table") {
            return (
              <div>
                {" "}
                <this.LabParameterTable />{" "}
              </div>
            );
          } else if (field.fieldType === "document") {
            return <div></div>;
          }
          //return <li key={field.fieldSlug}>{field.fieldType}</li>;
        })}

        <this.BottomButton />
        <br />
        <strong>
          Steps : {this.state.start + 1} / {this.state.sliceArray.length - 1}
        </strong>
      </div>
    );
  }

  AddNewPost = () => {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}

        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="List Form"
            subtitle="List Form Fields"
            className="text-sm-left"
          />
        </Row>
        <Row>
          <this.TopTab />
          <Col lg="12">
            <this.RenderFields />
          </Col>
        </Row>
      </Container>
    );
  };

  componentDidMount() {
    this.getFormFields();
  }

  render() {
    if (this.state.isCompleted) {
      return <Redirect to="../dashbord" />;
    }
    if (this.state.sliceArray.length < 1) {
      return (
        <div
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            marginLeft: "40%"
          }}
        >
          <Row className="justify-content-md-center">
            <Col lg="2">
              <ReactLoading type={"spinningBubbles"} color={"#5af"} />
              {/* <h2>Loading...</h2> */}
            </Col>
          </Row>
        </div>
      );
    } else if (this.state.isLoading) {
      return (
        <div
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            marginLeft: "40%"
          }}
        >
          <Row className="justify-content-md-center">
            <Col lg="2">
              <ReactLoading type={"spinningBubbles"} color={"#5af"} />
              {/* <h2>Loading...</h2> */}
            </Col>
          </Row>
        </div>
      );
    } else {
      return this.AddNewPost();
    }
  }
}
