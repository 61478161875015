export default function() {
  return [
    {
      title: "Dashboard",
      to: "/dashbord",
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: ""
    },

    {
      title: " Completed Patients Data ",
      htmlBefore: '<i class="material-icons">warning</i>',
      to: "/list-formdata"
    },
    {
      title: " Incomplete Patients Data ",
      htmlBefore: '<i class="material-icons">healing</i>',
      to: "/incomplete"
    },
    {
      title: " Profile ",
      htmlBefore: '<i class="material-icons">account_circle</i>',
      to: "/profile"
    },

    {
      title: "Logout ",
      htmlBefore: '<i class="material-icons">&#xE879;</i>',
      to: "/logout"
    }
  ];
}
