import React, { Component } from "react";
import { NavItem, NavLink } from "shards-react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  CardBody,
  CardHeader,
  Card,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../ApiUrl");

export default class ListFormData extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.getData = this.getData.bind(this);

    this.state = {
      limit: 20,
      page: 1,
      patientData: [],
      open: false,
      nameAndId: {
        data: []
      }
    };
  }

  async getData() {
    let page = this.state.page;
    let limit = this.state.limit;
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

    let response = await axios({
      method: "get",
      url: `${URL.BASE_URL}/forms/getfinishedlist/${page}/${limit}`,

      headers: { Authorization: `Bearer ${token}` }
    });
    console.log(response.data.data);

    this.setState({
      patientData: response.data.data,
      token: token
    });
    console.log(this.state.patientData);
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  ModalPopUp(data) {
    this.setState({
      open: !this.state.open,
      nameAndId: data
    });
    console.log("--**-*-*--*");
    console.log(data.data[0]);
  }

  deletePatient = async id => {
    let token = this.state.token;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let response = await axios
      .delete(`${URL.BASE_URL}/forms/delete/${id}`, config)
      .catch(function(error) {
        console.log(error);
      });
    if (response.data.statusCode === 200) {
      this.getData();
      this.toggle();
    }
  };

  RenderTable = () => {
    return (
      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <h6 className="m-0">Active Users</h6>
        </CardHeader>
        <CardBody className="p-0 pb-3">
          <table className="table mb-0">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0">
                  #
                </th>
                <th scope="col" className="border-0">
                  Name
                </th>
                <th scope="col" className="border-0">
                  Center
                </th>
                <th scope="col" className="border-0">
                  Serial number
                </th>
                <th scope="col" className="border-0">
                  IP No
                </th>
                <th scope="col" className="border-0">
                  Re-Admissions
                </th>
                <th scope="col" className="border-0">
                  Options
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.patientData.map((data, no) => {
                return (
                  <tr>
                    <td>{no + 1}</td>
                    <td>
                      {data.data[0].fields.name_149} <br />
                    </td>

                    <td>{data.data[0].fields.centre_525}</td>
                    <td>{data.data[0].fields.serial_number_833}</td>
                    <td>{data.data[0].fields.hospital_ip_number_643}</td>
                    <td>
                      <Button pill theme="primary" size="sm" disabled>
                        {data.count - 1}
                      </Button>{" "}
                    </td>
                    <td>
                      {/* 
                      <Link to={`patientdata/${data._id}`}>
                        <Button theme="success" className="mb-2 mr-1">
                          Edit <i class="fas fa-edit"></i>
                        </Button>
                      </Link>
                      */}
                      <Button
                        theme="success"
                        className="mb-2 mr-1"
                        onClick={() => this.ModalPopUp(data)}
                      >
                        Edit <i class="fas fa-edit"></i>
                      </Button>

                      <Link to={`followview/${data.data[0].userId}`}>
                        <Button className="mb-2 mr-1" theme="primary">
                          Follow Up
                        </Button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div>
            <Modal open={this.state.open} size="lg" toggle={this.toggle}>
              <ModalHeader>Total Admissions</ModalHeader>
              <ModalBody>
                <br />
                <table
                  style={{
                    width: "100%"
                  }}
                >
                  <thead className="bg-light">
                    <tr>
                      <th>Admission Date</th>
                      <th>Edit</th>
                      <th>Delete</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.nameAndId.data.map((data2, no) => {
                      let dateOfAdd;
                      let status = "Not Completed";
                      if (data2.fields) {
                        dateOfAdd = data2.fields.date_of_admission_153;
                      }
                      if (data2.fields) {
                        if (data2.fields.isCompleted) {
                          status = " Completed ";
                        }
                      }
                      return (
                        <tr>
                          <td>{dateOfAdd}</td>
                          <td>
                            <Link to={`patientdata/${data2.userId}`}>
                              <Button squared theme="primary" size="sm">
                                Edit <i class="fas fa-edit"></i>
                              </Button>
                            </Link>
                          </td>
                          <td>
                            <Button
                              squared
                              theme="danger"
                              size="sm"
                              onClick={() => this.deletePatient(data2.userId)}
                            >
                              Delete <i class="fas fa-trash-alt"></i>
                            </Button>{" "}
                          </td>
                          <td>{status}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </ModalBody>
              <ModalFooter>
                <Button
                  theme="success"
                  className="mx-auto"
                  onClick={this.toggle}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </CardBody>
      </Card>
    );
  };

  AddNewPost = () => {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="List Form"
            subtitle="List Form Fields"
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col md="12">
            <this.RenderTable />
          </Col>
        </Row>
      </Container>
    );
  };

  componentDidMount() {
    this.getData();
    console.log(this.props.match.params.id);
  }

  render() {
    return this.AddNewPost();
  }
}
