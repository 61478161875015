import React, { Component } from "react";
import { NavItem, NavLink } from "shards-react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  CardBody,
  CardHeader,
  Card,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormSelect
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../ApiUrl");

export default class ListFormData extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.getData = this.getData.bind(this);
    this.Pagination = this.Pagination.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      limit: 100,
      page: 1,
      patientData: [],
      open: false,
      nameAndId: {
        fields: {}
      }
    };
  }

  async getData(l, p) {
    // console.log(l, p);
    let page = this.state.page;
    let limit = this.state.limit;
    if (l) {
      limit = l;
      page = 1;
    }
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

    let response = await axios({
      method: "get",
      url: `${URL.BASE_URL}/forms/getincomplist/${page}/${limit}`,

      headers: { Authorization: `Bearer ${token}` }
    });

    this.setState({
      patientData: response.data.data,
      token: token
    });
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  toggle2() {
    this.setState({
      open2: !this.state.open2
    });
  }

  ModalPopUp(data) {
    this.setState({
      open: !this.state.open,
      nameAndId: data
    });
    console.log(data);
  }

  deletePatient = async id => {
    let token = this.state.token;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let response = await axios
      .delete(`${URL.BASE_URL}/forms/delete/${id}`, config)
      .catch(function(error) {
        console.log(error);
      });
    if (response.data.statusCode === 200) {
      this.getData();
      this.toggle();
    }
  };

  handleChange(e) {
    this.setState({
      [e.target.name]: Number(e.target.value)
    });
    if (e.target.name === "limit") {
      this.getData(e.target.value);
    } else if (e.target.name === "nextBtn") {
      this.setState({
        page: this.state.page + 1
      });
      this.getData();
    } else if (e.target.name === "prevBtn" && this.state.page > 0) {
      this.setState({
        page: this.state.page - 1
      });
      this.getData();
    }
  }

  Pagination = () => {
    return (
      <>
        <Row>
          <Col lg="4">
            <FormSelect
              onChange={this.handleChange}
              id="feInputState"
              name="limit"
            >
              <option>50</option>
              <option>100</option>
              <option>200</option>
              <option>500</option>
            </FormSelect>
          </Col>
          <Col lg="1">
            <Button
              onClick={this.handleChange}
              name="prevBtn"
              theme="warning"
              className="mb-2 mr-1"
            >
              Prev
            </Button>
          </Col>
          <Col lg="1">
            <Button
              onClick={this.handleChange}
              name="nextBtn"
              theme="info"
              className="mb-2 mr-1"
            >
              Next
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  RenderTable = () => {
    return (
      <Card small className="mb-4">
        <CardHeader className="border-bottom"></CardHeader>
        <CardBody className="p-0 pb-3">
          <table className="table mb-0">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0">
                  #
                </th>
                <th scope="col" className="border-0">
                  Patient Id
                </th>
                <th scope="col" className="border-0">
                  In Patient Number
                </th>
                <th scope="col" className="border-0">
                  Covid Status
                </th>
                <th scope="col" className="border-0">
                  Date of Admission
                </th>

                <th scope="col" className="border-0">
                  Options
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.patientData.map((data, no) => {
                return (
                  <tr>
                    <td>{no + 1}</td>
                    <td>{data.fields.patient_id_634}</td>
                    <td>{data.fields.in_patient_number_958}</td>

                    <td>{data.fields.covid_status_103}</td>
                    <td>{data.fields.date_of_admission_741}</td>
                    <td>
                      <Link to={`patientdata/${data._id}`}>
                        <Button theme="success" className="mb-2 mr-1">
                          Edit <i class="fas fa-edit"></i>
                        </Button>
                      </Link>

                      <Button
                        theme="danger"
                        className="mb-2 mr-1"
                        onClick={() => this.ModalPopUp(data)}
                      >
                        Delete <i class="fas fa-trash-alt"></i>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div>
            <Modal open={this.state.open} toggle={this.toggle}>
              <ModalHeader>
                Are you sure you want to delete this item?
              </ModalHeader>
              <ModalBody>
                {this.state.nameAndId.fields.name} will be deleted <br />
                <Button
                  theme="danger"
                  className="mb-2 mr-1"
                  onClick={() => this.deletePatient(this.state.nameAndId._id)}
                >
                  Yes, Delete
                </Button>
                <Button
                  theme="success"
                  className="mb-2 mr-1"
                  onClick={this.toggle}
                >
                  Cancel
                </Button>
              </ModalBody>
            </Modal>
          </div>
        </CardBody>
      </Card>
    );
  };

  AddNewPost = () => {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Incompleted Patients"
            subtitle=""
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col md="12">
            <this.RenderTable />
          </Col>
        </Row>
      </Container>
    );
  };

  componentDidMount() {
    this.getData();
    console.log(this.props.match.params.id);
  }

  render() {
    return this.AddNewPost();
  }
}
